import styles from 'styles/TagStates.module.scss';
import { colorsButton } from '../Orders/helpers';

function TagStates({ state, title }) {
  return (
    <div
      style={{
        backgroundColor: `${colorsButton(state)}`,
      }}
      className={styles.container}
    >
      <p className={styles.title}>{title}</p>
    </div>
  );
}

export default TagStates;

import Head from 'next/head';
import Image from 'next/image';
import styles from 'styles/Home.module.scss';
import Login from 'components/Login';
import { useContext, useEffect } from 'react';
import { AdminContext } from 'Context';
import logout from 'services/logout';
import Home from 'components/Home';
import getshop from 'services/getshop';


export default function HomePage() {
  const { user, setShop } = useContext(AdminContext)
  useEffect(() => {
    if (user) {
      getshop(user.user_id)
        .then(setShop)
    }
  }, [user])

  return (
    <div className={styles.container}>
      <Head>
        <title>Organizer Nilus</title>
        <link rel='icon' href='./favicon.png' />
      </Head>
      <main>
        {
          !user ?
            <Login /> :
            <>
              <Home />
            </>
        }
      </main>
    </div>
  );
}

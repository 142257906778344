import formatoNumero from 'services/formatoNumero';
import styles from 'styles/Orders.module.scss';
import TagStates from 'components/TagStates';
import dateFormat from 'services/dateFormat';
import { useContext } from 'react';
import { AdminContext } from 'Context';
import Image from 'next/image';
import nameStatus from '../../services/namestatus';

function Orders() {
  let { ordersOfTheWeek, router } = useContext(AdminContext);
  // const lineGenerator = () => {
  //   let allowed = ordersOfTheWeek.every(
  //     (pedido) => pedido.data.estado !== 'anulado'
  //   );
  //   let cancelled = ordersOfTheWeek.every(
  //     (pedido) => pedido.data.estado === 'anulado'
  //   );
  //   return !allowed && !cancelled;
  // };

  return ordersOfTheWeek !== null ? (
    <>
      <div className={styles.containerOrders}>
        <div className={styles.containerCards}>
          {ordersOfTheWeek?.map((singleOrder, i) => {
            if (
              singleOrder.order.status === 'pending_confirmation'
            ) {
              return (
                <div
                  className={styles.container2}
                  key={i}
                  style={{ marginBottom: '20px' }}
                  onClick={() => router.push(`/pedido/${singleOrder.order.id}`)}
                >
                  <div className={styles.containerCard}>
                    <div className={styles.flex}>
                      <div className={styles.containerNameCard}>
                          <p className={styles.tagId}>
                            #{singleOrder.order.code}
                          </p>
                        <span className={styles.name}>
                          {singleOrder?.customer.company_name}
                        </span>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p className={styles.symbol}>$</p>{' '}
                          <p className={styles.number}>
                            {formatoNumero(
                              parseFloat(singleOrder.order.total.value)
                            )}
                          </p>
                        </div>

                        
                      </div>
                    </div>
                    <div className={styles.containerDate}>
                      <p className={styles.date}>
                        {singleOrder.order.selected_delivery_date.day}/{singleOrder.order.selected_delivery_date.month}/{singleOrder.order.selected_delivery_date.year}
                      </p>
                      <TagStates
                        title={nameStatus(singleOrder.order.status).toUpperCase()}
                        state={nameStatus(singleOrder.order.status)}
                      />
                    </div>
                  </div>
                </div>
              );
            }
          })}
          {ordersOfTheWeek?.map((singleOrder, i) => {
            if (singleOrder.order.status === 'confirmed') {
              return (
                <div
                  className={styles.container2}
                  key={i}
                  style={{ marginBottom: '20px' }}
                  onClick={() => router.push(`/pedido/${singleOrder.order.id}`)}
                >
                  <div className={styles.containerCard}>
                    <div className={styles.flex}>
                      <div className={styles.containerNameCard}>
                        <p className={styles.tagId}>
                          #{singleOrder.order.code}
                        </p>
                        {singleOrder?.data?.numero_pedido && (
                          <p className={styles.tagId}>
                            #{singleOrder.order.code}
                          </p>
                        )}
                        <span className={styles.name}>
                          {singleOrder?.data?.datoscomprador?.nombre?.toUpperCase()}
                        </span>
                        <p className={styles.nameClient}>
                          {singleOrder?.customer.company_name}
                        </p>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p className={styles.symbol}>$</p>{' '}
                          <p className={styles.number}>
                          {formatoNumero(
                              parseFloat(singleOrder.order.total.value)
                            )}
                          </p>
                        </div>

                      </div>
                    </div>
                    <div className={styles.containerDate}>
                      <p className={styles.date}>
                        {singleOrder.order.selected_delivery_date.day}/{singleOrder.order.selected_delivery_date.month}/{singleOrder.order.selected_delivery_date.year}
                      </p>
                      <TagStates
                        title={nameStatus(singleOrder.order.status).toUpperCase()}
                        state={nameStatus(singleOrder.order.status)}
                      />
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>

        {/* {lineGenerator() && <div className={styles.separator} />} */}

        <div className={styles.containerCards} style={{ opacity: '0.5' }}>
          {ordersOfTheWeek?.map((singleOrder, i) => {
            if (singleOrder.order.status === 'canceled') {
              return (
                <div
                  className={styles.container2}
                  key={i}
                  style={{ marginBottom: '20px' }}
                  onClick={() => router.push(`/pedido/${singleOrder.order.id}`)}
                >
                  <div className={styles.containerCard}>
                    <div className={styles.flex}>
                      <div className={styles.containerNameCard}>
                          <p className={styles.tagId}>
                            #{singleOrder.order.code}
                          </p>
                        <span className={styles.name}>
                          {singleOrder?.customer.company_name}
                        </span>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p className={styles.symbol}>$</p>{' '}
                          <p className={styles.number}>
                            {formatoNumero(
                              parseFloat(singleOrder.order.total.value)
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.containerDate}>
                      <p className={styles.date}>
                        {singleOrder.order.selected_delivery_date.day}/{singleOrder.order.selected_delivery_date.month}/{singleOrder.order.selected_delivery_date.year}
                      </p>
                      <TagStates
                        title={nameStatus(singleOrder.order.status).toUpperCase()}
                        state={nameStatus(singleOrder.order.status)}
                      />
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  ) : (
    <div
      style={{
        minHeight: '60vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px 50px',
      }}
    >
      <img src='/imgs/box1.svg' />
      <h1 className={styles.ordersempty}>
        ¡No tienes pedidos <br /> en ésta fecha!
      </h1>
      <h2 className={styles.subtitle}>
        Recuerda difundir “El Mercadito” <br />
        con tus vecinos, familia y amigos <br />
        para aumentar tus ingresos.
      </h2>
    </div>
  );
}

export default Orders;

import styles from 'styles/FilterByDate.module.scss';
import { AdminContext } from 'Context';
import { useContext, useEffect, useState } from 'react';
import Image from 'next/image';

const nameMonths = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

function FilterByDate() {
  const [pedidosActivos, setPedidosActivos] = useState([]);
  const [fechaInicial, setFechaInicial] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [total, setTotal] = useState(null);
  const [month, setMonth] = useState(null);
  let {
    indexWeek,
    deliveryWeeks,
    setIndexWeek,
    pedidosSemana,
    setTotalDescuento,
  } = useContext(AdminContext);

  useEffect(() => {
    let first_date = new Date(deliveryWeeks[indexWeek - 1]);
    let last_date = new Date(deliveryWeeks[indexWeek]);
    setFechaInicial(first_date.getDate());
    setFechaFinal(last_date.getDate());
    setMonth(first_date.getMonth() + 1);
  }, [indexWeek, deliveryWeeks]);

  const currentMonth = nameMonths[month - 1];

  // useEffect(() => {
  //   let array = [];
  //   let nuevototal = 0;
  //   let discount = 0;
  //   if (pedidos !== null) {
  //     pedidosSemana.forEach((singlePedido) => {
  //       if (
  //         singlePedido?.data?.estado !== 'en_proceso' &&
  //         singlePedido?.data?.estado !== 'anulado' &&
  //         singlePedido?.data?.estado !== 'pendiente'
  //       ) {
  //         array.push(singlePedido);
  //         nuevototal = nuevototal + singlePedido?.data?.total;
  //         discount =
  //           discount +
  //           parseFloat(
  //             singlePedido?.data?.total_con_descuento !== undefined
  //               ? singlePedido?.data?.total_con_descuento
  //               : singlePedido?.data?.total
  //           );
  //       }
  //     });
  //     setPedidosActivos(array);
  //     setTotal(parseFloat(nuevototal.toFixed(2)));
  //     setTotalDescuento(parseFloat(discount.toFixed(2)));
  //   }
  // }, [pedidosSemana, indexWeek, pedidos, setTotalDescuento]);

  const handleSemanaSiguiente = () => {
    indexWeek < deliveryWeeks.length - 1 && setIndexWeek(indexWeek + 1);
  };

  const handleSemanaAnterior = () => {
    indexWeek > 1 && setIndexWeek(indexWeek - 1);
  };

  return (
    deliveryWeeks !== null &&
    <div className={styles.containerFilter}>
      <div className={styles.containerArrows}>
        <Image
          src={
            indexWeek === 1
              ? '/imgs/buttonBackDisabled.svg'
              : '/imgs/buttonBackPrimary.svg'
          }
          alt='flecha'
          className={styles.arrowIcons}
          onClick={handleSemanaAnterior}
          width={60}
          height={60}
        />
        <div>
          <h2 className={styles.day}>
            Del {fechaInicial} al {fechaFinal}
          </h2>
          <p className={styles.month}>{currentMonth}</p>
          <p className={styles.quantity}>{pedidosActivos?.length} Pedidos</p>
        </div>
        <Image
          src={
            indexWeek === deliveryWeeks.length - 1
              ? '/imgs/buttonNextDisabled.svg'
              : '/imgs/buttonNextPrimary.svg'
          }
          alt='flecha'
          className={styles.arrowIcons}
          onClick={handleSemanaSiguiente}
          width={60}
          height={60}
        />
      </div>
    </div>
  );
}

export default FilterByDate;

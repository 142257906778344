import styles from 'styles/Header.module.scss';
import { useContext, useEffect } from 'react';
import { AdminContext } from 'Context';
import ButtonHelp from 'components/ButtonHelp';
import ButtonSection from 'components/ButtonSection';

function Header() {
  let { user } = useContext(AdminContext)

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const name = user.first_name;
  const capitalize = capitalizeFirstLetter(name);

  const showGreeting = () => {
    const date = new Date();
    const hour = date.getHours();
    let text = '';

    if (hour >= 0 && hour < 12) {
      text = 'Buen día';
    }

    if (hour >= 12 && hour < 18) {
      text = 'Buenas tardes';
    }

    if (hour >= 18 && hour < 24) {
      text = 'Buenas noches';
    }
    return text;
  };

  const whatsappAr = '+5491154704612';
  const whatsappMx = '+5215579772293';

  return (
    <div className={styles.containerHeader}>
      <div className={styles.containerIcons}>
        <img
          src={'/imgs/logoNilus.png'}
          alt='logo'
          className={styles.logoNilus}
        />
        {/* {pais === 'ar' && (
          <ButtonHelp
            num={whatsappAr}
            title='AYUDA'
            icon={'/imgs/ws-icon.png'}
          />
        )}
        {pais === 'mx' && (
          <ButtonHelp
            num={whatsappMx}
            title='AYUDA'
            icon={'/imgs/ws-icon.png'}
          />
        )} */}
      </div>
      <h1 className={styles.welcomeTitle}>
        ¡{showGreeting()}, {capitalize}!
      </h1>
      <div className={styles.containerButtons}>
        <ButtonSection
          title={'Nuevo pedido'}
          icon='/imgs/addOrder.svg'
          route={`/nuevopedido`}
        />
        <ButtonSection
          title={'Clientes'}
          icon='/imgs/clients.svg'
          route={`/clientes`}
        />
      </div>
    </div>
  );
}

export default Header;

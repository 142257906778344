import { useContext, useEffect, useState } from 'react';
import styles from 'styles/Sucursal.module.scss';
import Header from 'components/Header';
import FilterByDate from 'components/FilterByDate';
import Orders from 'components/Orders';
import { AdminContext } from 'Context';
import Login from 'components/Login';

export default function Home() {
  let {
    pedidosSemana,
    usuarioLogueado,
    router,
    sucursal,
    loading,
    deliveryWeeks,
    email,
    vendedor,
    orders
  } = useContext(AdminContext);

  const handleDetalle = (id) => {
    router.push(`/${username}/${id}`);
  };

  return (
    <>
      {deliveryWeeks !== null ? (
        <div className={styles.sucursal}>
          <div className={styles.sub}>
            <div>
              <Header/>
            </div>

            <div>
              <FilterByDate />
            </div>
          </div>
          <div className={styles.pedidoindividual}>
            <Orders
              pedidos={pedidosSemana}
              handleDetalle={handleDetalle}
              sucursal={sucursal}
            />
          </div>
        </div>
      ) : (
        <div className={styles.sucursal}>
          <div className={styles.sub}>
            <div>
              <Header vendedor={vendedor} />
            </div>
          </div>
          <div
            style={{
              padding: '30px',
              paddingTop: '20vh',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src='/imgs/box2.svg' />
            <h1 className={styles.sucursalempty}>
              ¡Te damos la bienvenida a tu
              <br /> espacio de trabajo!
            </h1>
            <p className={styles.subtitle}>
              Aquí vas a encontrar toda la <br />
              información que necesitas <br />
              de tus pedidos.
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export const colorsButton = (estado) => {
  const enproceso = 'grey';
  const confirmado = '#57c157';
  const pendiente = '#ff8f3e';
  const anulado = '#9eaeba';
  const entregado = '#ba49ff';
  const completado = '#37afff';
  if (estado === 'en_proceso') {
    return enproceso;
  }
  if (estado === 'confirmado') {
    return confirmado;
  }
  if (estado === 'pendiente') {
    return pendiente;
  }
  if (estado === 'anulado') {
    return anulado;
  }
  if (estado === 'entregado') {
    return entregado;
  }
  if (estado === 'completado') {
    return completado;
  }
};

import { useEffect, useState, useContext } from 'react';
import { Button } from '@mui/material';
import loadScript from 'modules/loadScript';
import { useRouter } from 'next/router';
import { AdminContext } from 'Context';

const GOOGLE_CLIENT_PUBLIC_ID = '562045597908-8569om9p2ld36l9mgqt9hnn4jvcc68q8.apps.googleusercontent.com';

const initializeGoogleButton = (googleInstance, handleCredentialResponse) => {
  googleInstance?.accounts.id.initialize({
    client_id: GOOGLE_CLIENT_PUBLIC_ID,
    callback: handleCredentialResponse,
  });
  googleInstance?.accounts.id.renderButton(
    document.getElementById('gButton'),
    { theme: 'outline', size: 'large' },
  );
};

const navigateHome = (router) => {
  router.replace('/');
};

const GoogleSigningButton = () => {
  const [displayGoogleButton, setDisplayGoogleButton] = useState(false);
  const router = useRouter();

  const { user, setUser } = useContext(AdminContext)
  // eslint-disable-next-line no-undef
  const googleInstance = typeof google !== 'undefined' ? google : null;
  const handleCredentialResponse = async (response) => {
    const res = await fetch('/api/login', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        gjwt: response.credential,
      }),
    });
    const userData = await res.json();
    setUser(userData);
    navigateHome(router);
    // store userdata on session storage
  };
  useEffect(() => {
    let obj = window;
    waitUntilGoogleToExist(obj, googleInstance, setDisplayGoogleButton);
    if (user) navigateHome(router);
    else {
      loadScript({
        src: 'https://accounts.google.com/gsi/client',
        id: 'google_gsi_client',
      });
    }
  }, [user, router]);
  useEffect(() => {
    if (displayGoogleButton) initializeGoogleButton(googleInstance, handleCredentialResponse);
  }, [displayGoogleButton]);

  // eslint-disable-next-line no-unused-vars
  if (!displayGoogleButton) return null;
  return <Button variant="text" id="gButton" type="button" aria-label="Login with google" style={{backgroundColor: 'transparent'}}>Login with google</Button>;
};

export default GoogleSigningButton;


function waitUntilGoogleToExist(obj, googleInstance, setDisplayGoogleButton) {
  Object.defineProperty(window, "google", {
    configurable: true,
    set(v) {
      Object.defineProperty(obj, "google", {
        configurable: true, enumerable: true, writable: true, value: v
      });
      setDisplayGoogleButton(true);
    }
  });
}


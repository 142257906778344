export default function nameStatus(status) {

    switch(status) {
        case 'pending_confirmation':
            return 'pendiente'
        case 'confirmed':
            return 'confirmado'
        case 'shipping':
            return 'en camino'
        case 'delivered':
            return 'entregado'
        case 'canceled':
            return 'anulado'
        default:
            return status
    }
}

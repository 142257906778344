import Image from 'next/image';
import styles from 'styles/Login.module.scss';

import GoogleSigninButton from 'modules/auth/SigninGoogleButton';

export default function Login() {
  return (
      <div className={styles.login}>
        <div className={styles.logo}>
          <Image
            src='/imgs/logo.svg'
            layout='responsive'
            width={200}
            height={210}
            alt='logo'
          />
        </div>
        <h1 style={{ color: 'white', marginBottom: '20px', fontWeight: '500' }}>
          ACCESO PRIVADO
        </h1>
        <div>
          <GoogleSigninButton />
        </div>
      </div>
  );
}

import { useRouter } from 'next/router';
import styles from 'styles/ButtonSection.module.scss';


function ButtonSection({ icon, title, route }) {
  const router = useRouter()
  return (
    <div className={styles.container} style={{ width: '48%' }} onClick={() => router.push(route)}>
      <img src={icon} alt='icon' className={styles.icon} />
      <p className={styles.title}>{title}</p>
    </div>
  );
}

export default ButtonSection;

import styles from 'styles/ButtonHelp.module.scss';

function ButtonHelp({ icon, title, num }) {
  return (
    <div className={styles.container}>
      <a
        href={`https://wa.me/${num}`}
        target='_blank'
        rel='noopener noreferrer'
        className={styles.tagA}
      >
        <img src={icon} alt='ws' className={styles.icon} />
        <p className={styles.title}>{title}</p>
      </a>
    </div>
  );
}

export default ButtonHelp;
